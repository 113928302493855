<template>
  <div class="table-box">
    <div class='title'>
      <span>{{ title }}</span>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th v-for="(item,index) in header" :key="index" :style="'flex:'+ widths[index] +';text-align:' +aligns[index]">
          {{ item }}
        </th>
      </tr>
      </thead>
      <tbody class="table-body">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <vue-seamless-scroll v-else
                           ref="seamlessScroll"
                           :class-option="seamlessScrollOption"
                           :data="listData"
                           class="main-scroll"
                           style="
          height: 300px;
          overflow: hidden;
          width: 730px;
          color: white;
          font-size: 14px;
          text-align: center;"
      >
        <tr v-for="(institution,sIndex) in listData" :key="sIndex">
          <td :style="'flex:'+ widths[0]">
            <vue-seamless-scroll
                :class-option="textSetOption(5)"
                :data="textToList(institution.province)"
                class="text-scroll"
            >
             {{ institution.province }}
            </vue-seamless-scroll>
          </td>
          <td :style="'flex:'+ widths[1]">

            <vue-seamless-scroll
                :class-option="textSetOption(5)"
                :data="textToList(institution.city)"
                class="text-scroll"
            >
              {{ institution.city }}
            </vue-seamless-scroll>
          </td>
          <td :style="'flex:'+ widths[2]">
            <vue-seamless-scroll
                :class-option="textSetOption(5)"
                :data="textToList(institution.district)"
                class="text-scroll"
            >
              {{ institution.district }}
            </vue-seamless-scroll>

          </td>
          <td :style="'flex:'+ widths[3] + ';padding-left:0'">
            <vue-seamless-scroll
                :class-option="textSetOption(12)"
                :data="textToList( institution.name )"
                class="text-scroll"
            >
              {{ institution.name }}
            </vue-seamless-scroll>

          </td>
          <td :style="'flex:'+ widths[4]">
            <vue-seamless-scroll
                :class-option="textSetOption(11)"
                :data="textToList(institution.foundDate)"
                class="text-scroll"
            >
              {{ institution.foundDate }}
            </vue-seamless-scroll>
          </td>
          <td :style="'flex:'+ widths[5]">
            {{ institution.bedCount }}
            <!--            <span style="font-size: 12px">张</span>-->
          </td>
          <td :style="'flex:'+ widths[6]">

            <div class="marquee">
              <vue-seamless-scroll
                  :class-option="textSetOption(12)"
                  :data="textToList(institution.phone)"
                  class="text-scroll"
              >
                {{ institution.phone }}
              </vue-seamless-scroll>
            </div>
          </td>
        </tr>
      </vue-seamless-scroll>
      </tbody>
    </table>

  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'

// import  'vue-marquee-ho/dist/vue-marquee.min.css'
import resize from './mixins/resize'
import {EventBus} from "@/utils/EventBus";

export default {
  mixins: [resize],
  props: {
    formData: {
      type: Array,
      default: () => ([]),
    },
    regionCode: {
      type: String,
      default: '',
    }
  },
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      header: ['\u3000省', '\u3000市', '\u3000区', '机构名称', '成立日期', '床位数', '联系电话'],
      widths: [1.2, 1.2, 1.2, 3.2, 1.5, 1, 2.5],
      aligns: ['left', 'left', 'left', 'center', 'center', 'center', 'center'],
      listData: [],
      title: "明细信息一览",
      loading: true,
    };
  },
  mounted() {
    let that = this
    this.listData = this.formData
    this.loading = false
    EventBus.$on('organizationDetailEvent', (e) => {
      this.loading = true
      this.listData = []
      setTimeout(function () {
        that.listData = e
        that.loading = false
      }, 400)
    })
  },
  beforeDestroy() {
    EventBus.$off('organizationDetailEvent');
  },
  methods: {
    textToList(text) {
      let list = []
      list = text ? text.split('') : []
      return list
    },
    textSetOption(limitMoveNum = 5) {
      return {
        step: 0.15, // 数值越大速度滚动越快
        limitMoveNum: limitMoveNum, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  computed: {
    seamlessScrollOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
};
</script>
<style lang="scss" scoped>

::v-deep .table-body > div.main-scroll > div > div:last-child {
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 8px 10px;
  opacity: 0;
}

::v-deep .text-scroll > div > div:nth-child(2) {
  display: none !important;
}

.table-box {
  background: url("../assets/border-table.jpg") no-repeat;
  background-size: 100% 100%;
  width: 730px;
  height: 400px;
  padding: 10px 0 20px;
  overflow: hidden;

  > .title {
    font-size: 16.05px;
    font-weight: 400;
    text-align: center;
    color: #00fcfd;
    letter-spacing: 0.06px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
  }

  .table > thead {
    width: 100%;
  }

  .table tr {
    display: flex;
    font-size: 14px;
    width: 100%;
    padding: 8px 10px;
  }

  .table th {
    color: white;
    font-weight: bold;
    padding: 8px 10px;
    flex: 1;
    text-align: left;
    background-color: rgba(41, 105, 177, 0.3);
  }

  .table .table-body {
    height: 100%;
  }

  .table .table-body tr {
    gap: 10px;
    padding: 0 10px;

  }

  .table td {
    text-align: left;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    padding: 8px;
    font-size: 14px;

    > .marquee {
      > div {
        height: 100%;
        overflow: hidden;
        color: white;
        font-size: 13px;
        text-align: center;
        /** 优化字体抖动问题 */

        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;

      }
    }
  }
}
</style>
