<template>
  <div id="RightTop">
    <HeaderTitle :title="title"/>
    <div ref="pieChart" style="width: 100%; height: 380px;"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import resize from './mixins/resize'
import {EventBus} from "@/utils/EventBus";

require('echarts/theme/macarons') // echarts theme

export default {
  mixins: [resize],
  props: {
    formData: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      title: "各地访问情况",
      dataResource: [],
      myChart: "",
      option:"",
    }
  },
  created() {
  },
  mounted() {
    this.createChart()
    EventBus.$on('apiDataEvent', (e) => {
      this.title = e.regionName === "全国" ? '各地访问情况' : e.regionName + '访问情况'
      this.dataResource = []
      e.data.regionVisit.forEach(item => {
        this.dataResource.push({
          name: item.regionName, // 区域名称
          value: item.visitPercent // 区域占比
        })
      })
      this.option.series[0].data = this.dataResource
      this.myChart.clear()
      this.myChart.setOption(this.option,true);
    })
  },
  beforeDestroy() {
    EventBus.$off('apiDataEvent');
  },
  methods: {
    createChart() {
      const chartDom = this.$refs.pieChart;
      this.myChart = echarts.init(chartDom);
      this.formData.forEach(item => {
        this.dataResource.push({
          name: item.regionName, // 区域名称
          value: item.visitPercent // 区域占比
        })
      })
      this.option = {
        series: [
          {
            type: 'pie',
            radius: [30, '40%'],
            center: ['50%', '48%'],
            // minAngle: 30, //最小角度
            // startAngle: 270, //起始角度
            roseType: 'radius',
            avoidLabelOverlap: true, // 是否启用防止标签重叠策略
            itemStyle: {
              borderRadius: 2
            },
            label: {
              position: "top",
              alignTo: "edge",
              margin: 1,
              normal: {
                position: "outside", // 在内部显示，outside 是在外部显示
                lineStyle: {
                  width: 2
                },
                textStyle: {
                  color: '#01E3E6', // 标签文字的颜色
                  fontSize: "12px"
                },
                show: true,
                formatter: `{b}{c}%`,
              },
            },
            labelLayout: {
              hideOverlap: false,
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: this.dataResource
          }
        ]
      };

      this.myChart.setOption(this.option);
    }
  },
}
</script>
<style lang="scss" scoped>
#RightTop {
  height: 410px;
  width: 100%;
}
</style>
