<template>
  <div class="table-box">
    <div class='title'>
      <span>{{ title }}</span>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th v-for="(item,index) in header" :key="index" :style="'flex:'+ widths[index] +';text-align:' +aligns[index]">
          {{ item }}
        </th>
      </tr>
      </thead>
      <tbody class="table-body">
      <vue-seamless-scroll
          :class-option="seamlessScrollOption"
          :data="listData"
          class="main-scroll"
          style="
          height: 270px;
          overflow: hidden;
          width: 730px;
          color: white;
          font-size: 18px;
          text-align: center;"
      >
        <tr v-for="institution in listData" :key="institution.regionCode">
          <td :style="'flex:'+ widths[0]">

            {{ institution.regionName }}
          </td>

          <td :style="'flex:'+ widths[1]">
            {{ institution.organizationCount }}
          </td>
          <td :style="'flex:'+ widths[2]">
            {{ institution.civilUserCount }}

          </td>
          <td :style="'flex:'+ widths[3]">
            {{ institution.bedCount }}
            <!--            <span style="font-size: 12px">张</span>-->
          </td>
        </tr>
      </vue-seamless-scroll>
      </tbody>
    </table>

  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import {EventBus} from "@/utils/EventBus";

export default {
  props: {
    formData: {
      type: Array,
      default: () => ([]),
    },
  },
  components: {
    vueSeamlessScroll,
  },
  data() {
    return {
      header: ['地区', '机构数', '民政用户数', '床位数',],
      widths: [4.2, 1.2, 1.2, 3.2,],
      aligns: ['center', 'center', 'center', 'center'],
      listData: [],
      title: "机构&民政账号等"
    };
  },
  mounted() {
    this.listData = this.formData
    EventBus.$on('organizationEvent', (e) => {
      this.listData = e
    })
  },
  beforeDestroy() {
    EventBus.$off('organizationEvent');
  },
  methods: {},
  computed: {
    seamlessScrollOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 8, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 3000, // 单步运动停止的时间(默认值1000ms)
      };
    },


  },
};
</script>
<style lang="scss" scoped>

::v-deep .table-body > div.main-scroll > div > div:last-child {
  display: flex;
  width: 100%;
  font-size: 14px;
  padding: 8px 10px;
  opacity: 0;
}

::v-deep .text-scroll > div > div:nth-child(2) {
  display: none !important;
}

.table-box {
  background: url("../assets/border-table.jpg") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 360px;
  padding: 10px 0 20px;
  overflow: hidden;

  > .title {
    font-size: 16.05px;
    font-weight: 400;
    text-align: center;
    color: #00fcfd;
    letter-spacing: 0.06px;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
  }


  .table tr {
    display: flex;
    font-size: 15px;
    width: 100%;
    padding: 0 10px;
  }

  .table .table-body {
    height: 100%;
  }


  .table .table-body tr {
    gap: 10px;
  }

  .table th {
    color: white;
    font-weight: bold;
    padding: 8px 10px;
    flex: 1;
    text-align: left;
    background-color: rgba(41, 105, 177, 0.3);
  }

  .table td {
    text-align: center;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px;

    > .marquee {
      > div {
        height: 100%;
        overflow: hidden;
        color: white;
        font-size: 14px;
        text-align: center;
        padding: 0 10px;
        width: calc(100% - 20px);
      }
    }
  }
}
</style>
