/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
export function debounce(fn, delay) {
  var timer;
  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }
}


export function replaceChars(str, chars) {
  const pattern = chars.join('|'); // 将字符数组转换为正则表达式字符串
  const regex = new RegExp(pattern, 'g'); // 创建正则表达式对象
  return str.replace(regex, ''); // 替换掉字符串中的匹配字符
}

//逗号
export function numFormat(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}

// 鼠标隐藏
export function mouseHide(number= 2000) {
  let timer = null;

// 监听鼠标移动事件
  document.addEventListener('mousemove', function() {
    // 清除之前的定时器（如果存在）
    if (timer) {
      clearTimeout(timer);
    }

    // 当鼠标移动时，显示鼠标
    document.body.style.cursor = 'auto'; // 或者你想要的任何光标样式

    // 设置一个定时器，在鼠标停止移动2秒后隐藏鼠标
    timer = setTimeout(function() {
      // 隐藏鼠标
      document.body.style.cursor = 'none';
    }, number);
  });

// 监听鼠标离开窗口事件，确保在鼠标离开时清除定时器
  window.addEventListener('mouseleave', function() {
    if (timer) {
      clearTimeout(timer);
    }
  });
}
