<template>
  <div id="RightCenter">
    <HeaderTitle :title="title"/>
    <div ref="pieChart" style="width: 100%; height: 300px;"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import resize from './mixins/resize'
import {EventBus} from "@/utils/EventBus";

require('echarts/theme/macarons') // echarts theme

export default {
  mixins: [resize],
  props: {
    formData: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      title: "用户年龄分布",
      dataResource: [],
      option: "",
      myChart: ""
    }
  },
  created() {
  },
  mounted() {
    this.createChart()
    EventBus.$on('ageChartEvent', (e) => {
      this.title = `${e.regionName === "全国" ? '' : e.regionName}用户年龄分布`
      this.dataResource = e.data
      this.option.series[0].data = this.dataResource
      this.myChart.clear()
      this.myChart.setOption(this.option, true);
    })
  },
  beforeDestroy() {
    EventBus.$off('ageChartEvent');
  },
  methods: {
    createChart() {
      const chartDom = this.$refs.pieChart;
      this.myChart = echarts.init(chartDom);
      this.dataResource = this.formData
      this.option = {
        series: [
          {
            type: 'pie',
            radius: [0, "75%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: true, // 是否启用防止标签重叠策略
            itemStyle: {
              borderRadius: 1
            },
            label: {
              position: "top",
              alignTo: "edge",
              margin: 1,
              normal: {
                position: "outside", // 在内部显示，outside 是在外部显示
                // length: 50, //第一条线
                // length2: 20, //第二条线
                lineStyle: {
                  width: 2
                },
                textStyle: {
                  color: '#01E3E6', // 标签文字的颜色
                  fontSize: "18px"
                },
                show: true,
                formatter: `{b}\n{d}%`,
              },
            },
            labelLayout: {
              hideOverlap: false,
            },
            emphasis: {
              label: {
                show: true
              }
            },
            data: this.dataResource
          }
        ]
      };

      this.myChart.setOption(this.option);
    }
  },
}
</script>
<style lang="scss" scoped>
#RightCenter {
  width: 100%;
  height: 310px;
}
</style>
