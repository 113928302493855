
import axios from '@/utils/api.request'
/**
 * 获取统计数据
 * @param {number} regionCode 行政区划代码
 */
export function getSummaries(regionCode) {//上传文件
    return axios.request({
        url: `/v1/summaries`,
        method: 'get',
        params: {
            regionCode:regionCode
        },
    })
}

/**
 * 随机获取机构明细
 * @param {number} regionCode 行政区划代码
 */
export function getOrganizations(regionCode) {
    return axios.request({
        url: `/v1/organizations`,
        method: "get",
        params: {
            regionCode:regionCode
        },
    });
}
