<template>
  <div id="RightTop">
    <HeaderTitle :title="title"/>
    <div ref="pieChart" style="width: 100%; height: 660px;"></div>
  </div>
</template>

<script>
import echarts from 'echarts'
import resize from './mixins/resize'
import {EventBus} from "@/utils/EventBus";

require('echarts/theme/macarons') // echarts theme

export default {
  mixins: [resize],
  props: {
    formData: {
      type: Array,
      default: () => ([]),
    }
  },
  data() {
    return {
      title: "当月省内访问人数占比",
      dataResource: [],
      myChart: "",
      option: "",
      titleData: []// y轴数据
    }
  },
  created() {
  },
  mounted() {
    this.createChart()
    EventBus.$on('apiDataEvent', (e) => {


      this.dataResource = []
      this.titleData = []
      e.data.regionVisit.forEach(item => {
        this.titleData.push(item.regionName);// 区域名称)
        this.dataResource.push({
          name: item.regionName,
          value: item.visitPercent
        });
      })

      this.onSetChdrtData()

      this.option.yAxis.data = this.titleData
      this.option.series[0].data = this.dataResource
      this.myChart.clear()
      this.myChart.setOption(this.option, true);
    })
  },
  beforeDestroy() {
    EventBus.$off('apiDataEvent');
  },
  methods: {
    // 配置图表数据
    onSetChdrtData() {
      let  number = 14
      // 补全数据
      if (this.dataResource.length < number) {
        for (let i = 0; i < number - this.dataResource.length; i++) {
          this.titleData.push("")
          this.dataResource.push({
            name: '',
            value: "",
          });
        }
      }
      // 补全数据
      this.dataResource.reverse()
      this.titleData.reverse()
    },
    // 初始化图表
    createChart() {
      const chartDom = this.$refs.pieChart;
      this.myChart = echarts.init(chartDom);

      this.titleData = []
      this.dataResource = []
      this.formData.forEach(item => {
        this.titleData.push(item.regionName);// 区域名称)
        this.dataResource.push({
          name: item.regionName,
          value: item.visitPercent,
        });
      })

      this.onSetChdrtData()

      this.option = {
        textStyle: {
          color: '#fff'// 字体颜色
        },
        tooltip: {
          // 悬停指示
          trigger: 'item',
          formatter: '{b}  <b>{c}%</b>',
          backgroundColor: '#FFFFFF',
          textStyle: {
            fontSize: 14,
            padding: 20,
            color: '#515A6E'// 字体颜色
          }
        },
        yAxis: {
          data: this.titleData,
          axisTick: { // x轴刻度线
            show: false
          },
          splitLine: { // 网格线
            show: false
          },
          axisLine: { // 坐标轴线
            show: false
          },
          axisLabel: { // 调整文字倾斜角度(rotate=xxx)和颜色
            interval: 0,
            margin: 16,
            textStyle: {
              color: '#FFF',
              fontSize: 14
            }
          }
        },
        xAxis: {
          show: false,
          max: 100,
          min: 0,
          position: 'right'
        },
        grid: {
          left: 24,
          right: 40,
          bottom: 16,
          top: 24,
          containLabel: true
        },
        series: [{
          name: '收缴率',
          type: 'bar',
          stack: '使用情况',
          data: this.dataResource,
          barWidth: 14, // 柱图宽度
          barGap: 36, // 柱图之间的间距
          // 标签
          label: {
            normal: {
              show: true,
              fontSize: 12,
              position: 'right',
              // offset: [-15, -10],
              formatter: '{c}%' // 模板变量有 {a}、{b}、{c}、{d}，分别表示系列名，数据名，数据值，百分比。{d}数据会根据value值计算百分比
            }
          },
          // showBackground: true,
          // backgroundStyle: {
          //   // color: 'rgba(0,194,255,0.2)',
          //   barBorderRadius: [100, 100, 100, 100]
          // },
          itemStyle: {
            normal: {
              color: function (e) {
                let color = ['#FF7601', '#0783FF'];
                return color[e.dataIndex % 2]
              },
              // 设置柱子圆角
              barBorderRadius: [100, 100, 100, 100]
            }
          },
          markPoint: {
            symbol: 'pin', // 标记类型
            symbolSize: 10, // 图形大小
            itemStyle: {
              normal: {
                borderColor: 'green',
                borderWidth: 1, // 标注边线线宽，单位px，默认为1
                label: {
                  show: true,
                  fontSize: 14
                }
              }
            }
          }
        },
          {
            name: '',
            type: 'bar',
            stack: '使用情况',
            // data: [100, 100, 100, 100, 100],
            itemStyle: {
              barBorderRadius: [100, 100, 100, 100] // 统一设置四个角的圆角大小
            }
          }
        ]

      };

      this.myChart.setOption(this.option);
    }
  },
}
</script>
<style lang="scss" scoped>
#RightTop {
  padding-top: 0px;
  height: 700px;
  width: 100%;
}
</style>
