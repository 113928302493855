<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!--        顶部基础信息和logo区域-->
        <div class="header">
          <div class="header-left">
            <div class="today-tile">
              <span>{{ currentTime.date }}</span>
              <span>{{ currentTime.time }}</span>
              <span>{{ currentTime.currentDay }}</span>
            </div>
          </div>
          <div class="logo" @click="fullScreen">
            <img alt="" src="@/assets/logo.jpg" srcset="">
          </div>
          <div class="header-right">
            <div class="select-tile">
              <select v-model="regionCode" class="custom-select">
                <option v-for="(option, index) in options" :key="index" :value="option.regionCode">
                  {{ option.regionName }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!--        顶部基础信息和logo区域 end-->
        <div class="card-box">
          <div v-for="(item,index) in cardList" :key="index" :style="'background-image:url('+item.img+')'"
               class="card-tile">

            <div class="title">{{
                (item.name === 'organizationCount' || item.name === 'civilUserCount') ? regionName + item.title : item.title
              }}
            </div>
            <div class="value">
              <animate-number :key="item.count" :ref="item.name" :mode="openAnimateNumber" :to="item.count"
                              class="count"
                              from="0"/>
              <span class="unit">
              {{ item.unit }}
              </span>
            </div>
            <div v-if="item.subTitle" class="subTitle">
              {{ item.subTitle }}
              <img v-if="item.subValue>0" src="../assets/icon-up.png">
              <img v-else src="../assets/icon-down.png">
              <animate-number :key="item.subValue" :ref="item.subName" :class="item.subValue>0?'count':'countDown'" :formatter="setFormatter"
                              :mode="openAnimateNumber" :to="item.subValue" from="0"/>
              <span>%</span>
            </div>
          </div>
        </div>
        <div class="body-box">
          <div class="left">
            <ChinaMap :formData="apiData.organization" :isNationwide="isNationwide" @onChangeRegion="onChangeRegion"/>
          </div>
          <div class="center">
            <div v-if="isNationwide" class="center-top">
              <CenterChartTop :formData="regionVisit"/>
            </div>
            <div v-if="isNationwide" class="center-bottom">
              <CenterChartBottom :formData="binChartAge"/>
            </div>

            <div v-if="!isNationwide" class="center-program">
              <CenterProgram :formData="regionVisit"/>
            </div>

          </div>
          <div class="right">
            <div class="right-top" style="width: 100%">
              <RightTableTop :formData="organizationDetail"/>
            </div>
            <div class="right-center" style="width: 100%">
              <RightTableBotttom :formData="organization"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import drawMixin from '../utils/drawMixin'
import ChinaMap from './china-map.vue'
import CenterChartTop from './center-chart-top.vue'
import CenterChartBottom from "@/views/center-chart-bottom.vue";
import RightTableTop from './right-table-top.vue'
import RightTableBotttom from './right-table-bottom.vue'
import CenterProgram from './center-program.vue';
import {getOrganizations, getSummaries} from "@/api";
import {EventBus} from "@/utils/EventBus";
import {mouseHide} from "@/utils";

export default {
  components: {
    ChinaMap,
    CenterChartTop,
    CenterChartBottom,
    RightTableTop,
    RightTableBotttom,
    CenterProgram,
  },
  mixins: [drawMixin],
  data() {
    return {
      decorationColor: ['#568aea', '#000000'],
      loading: true,
      currentTime: {
        time: "",
        date: "",
        currentDay: "",
      },
      openAnimateNumber: 'auto', // 是否开启数据动画
      options: [],
      cardList: [],// 卡片数据
      mainList: [
        {
          title: "机构总数",
          count: 0,
          unit: "个",
          name: "organizationCount",
          img: require("../assets/card-1.jpg")
        },
        {
          title: "民政用户数",
          count: 0,
          unit: "个",
          name: "civilUserCount",
          img: require("../assets/card-2.jpg")
        },
      ],
      otherList: [
        {
          title: "访问次数",
          count: 0,
          unit: "次",
          name: "sessionCnt",
          subName: "sessionCntMonth",
          subTitle: "月同比",
          subValue: 0,
          isNationwide: true,
          img: require("../assets/card-3.jpg")
        },
        {
          title: "页面访问数",
          count: 0,
          unit: "次",
          name: "pageVisit",
          subName: "pageVisitMonth",
          subTitle: "月同比",
          subValue: 0,
          isNationwide: true,
          img: require("../assets/card-4.jpg")
        },
        {
          title: "床位数",
          count: 0,
          unit: "张",
          name: "bedCount",
          subValue: 0,
          isNationwide: false,
          img: require("../assets/card-3.jpg")
        },
      ],
      // 用户年龄分布 饼图数据
      binChartAge: [
        {
          name: "17岁以下",
          value: 0,
          data: "ageLess17Visit",
        },
        {
          name: "18-24岁",
          value: 0,
          data: "ageBetween18And24Visit",
        },
        {
          name: "25-29岁",
          value: 0,
          data: "ageBetween25And29Visit",
        },
        {
          name: "30-39岁",
          value: 0,
          data: "ageBetween30And39Visit",
        },
        {
          name: "40-49岁",
          value: 0,
          data: "ageBetween40And49Visit",
        },
        {
          name: "50岁以上",
          value: 0,
          data: "ageThan50Visit",
        },
        {
          name: "未知年龄",
          value: 0,
          data: "ageUnknownVisit",
        }
      ],
      regionVisit: [],// 各地访问情况
      organization: [], //机构数据
      organizationDetail: [], //机构明细
      regionCode: "",// 行政区域代码
      regionName: "全国",
      apiData: "",//接口数据
      isNationwide: true,// 判断是否为二级页面
      timer: null,// 日期定时器
      timerNationWide: null,//全国数据刷新定时器
      timerTime: 120 * 1000
    }
  },
  mounted() {
    // 定时更新时间
    this.timer = setInterval(() => {
      this.updateTime();
    }, 1000);
    this.onInitData()
    this.onNationWideTimer()

    /// 鼠标隐藏
    mouseHide()
  },
  watch: {
    regionCode(newVal) {
      this.isNationwide = newVal.length <= 0;
      this.regionName = this.options.find(item => {
        return item.regionCode === newVal
      }).regionName

      this.onInitData()
      clearInterval(this.timerNationWide)
      this.onNationWideTimer()
    },
  },
  //
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerNationWide)
  },
  methods: {
    // 整体定时器
    onNationWideTimer() {
      this.timerNationWide = setInterval(() => {
        this.onInitData()
      }, this.timerTime)
    },
    // 全屏按钮点击
    fullScreen() {
      // DOM对象的一个属性，可以用来判断当前是不是全屏模式【全屏:true,不是全屏：false】
      let full = document.fullscreenElement;
      if (!full) {
        // 文档根节点的方法requestFullscreen（）实现全屏模式
        document.documentElement.requestFullscreen();
      } else {
        // 文档根节点的方法exitFullscreen（）退出全屏
        document.exitFullscreen()
      }
    }
    ,
    // 初始化数据
    onInitData() {
      const regionCode = this.regionCode ? parseInt(this.regionCode) : ''
      getSummaries(regionCode).then(res => {
        if (res.status === 200) {

          this.apiData = res.data
          this.cardList = [] // 初始化卡片
          // 主卡片
          this.mainList.forEach(item => {
            item.count = 0
            item.count = res.data[item.name]

            // 手动刷新动画数字效果
            if (this.$refs[item.name]) {
              this.$refs[item.name][0].start()
            }
            this.cardList.push(item)
          })

          // 根据全国还是各省 卡片数据
          this.otherList.forEach(item => {
            item.count = 0
            item.count = res.data[item.name]
            // 顶部统计卡牌数据初始化
            // eslint-disable-next-line no-prototype-builtins
            if (item.hasOwnProperty('subValue')) {
              item.subValue = 0
              item.subValue = res.data[item.subName]
            }

            // 手动刷新动画数字效果
            if (this.$refs[item.name] && this.$refs[item.name].length > 0) {
              this.$refs[item.name][0].start()
            }

            if (this.$refs[item.subName] && this.$refs[item.subName].length > 0) {
              this.$refs[item.subName][0].start()
            }

            if (item.isNationwide === this.isNationwide) {
              this.cardList.push(item)
            }
          })

          // 各省下拉选择框
          if (this.isNationwide) {
            this.options = JSON.parse(JSON.stringify(res.data.organization))
            this.options.unshift({
              regionName: '全国',
              regionCode: ""
            })
          }
          // 用户年龄分布数据初始化
          this.binChartAge.forEach(item => {
            item.value = res.data[item.data]
          })

          // 各地访问情况 初始化
          this.regionVisit = res.data.regionVisit
          this.organization = JSON.parse(JSON.stringify(res.data.organization))
          this.onInitOrgan()

          // 用户年龄分布数据初始化
          EventBus.$emit('ageChartEvent', {
            'data': this.binChartAge,
            'regionName': this.regionName
          })

          // 传输更新数据到子组件中
          EventBus.$emit('apiDataEvent', {
            'data': res.data,
            'regionName': this.regionName
          })

          // 传输更新数据到子组件中
          EventBus.$emit('apiDataMapEvent', {
            'data': res.data,
            'regionName': this.regionName
          })

          // 机构和民政账号中
          EventBus.$emit('organizationEvent', this.organization)
        }
      }).finally(
          this.cancelLoading()// 加载完成
      )
    },
    // 初始化机构明细数据
    onInitOrgan() {
      const regionCode = this.regionCode ? parseInt(this.regionCode) : ''
      getOrganizations(regionCode).then(res => {
        if (res.status === 200) {
          this.organizationDetail = [...res.data]
          EventBus.$emit('organizationDetailEvent', this.organizationDetail)
        }
      })
    },
    // 点击地图
    onChangeRegion(e) {
      if (e) {
        this.regionCode = e.regionCode
      }
    },

    // loading效果
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },


    // 月同比数据格式
    setFormatter(num) {
      if (num < 0) {
        let number = Math.abs(num)
        return number.toFixed(2);
      }
      return num.toFixed(2)
    },

    // 格式化相关时间
    updateTime() {
      const dateObj = new Date();
      const hours = dateObj.getHours();
      const minutes = dateObj.getMinutes();
      const seconds = dateObj.getSeconds();
      // 格式化小时、分钟、秒数
      const hours1 = hours < 10 ? "0" + hours : hours;
      const minutes1 = minutes < 10 ? "0" + minutes : minutes;
      const seconds1 = seconds < 10 ? "0" + seconds : seconds;
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const day = dateObj.getDate();
      // 格式化月、日
      const month1 = month < 10 ? "0" + month : month;
      const day1 = day < 10 ? "0" + day : day;
      const day2 = dateObj.getDay();
      this.currentTime.currentDay = this.getDayName(day2);
      this.currentTime.time = `${hours1}:${minutes1}:${seconds1}`;
      this.currentTime.date = `${year}/${month1}/${day1}`;
      // console.log("111", this.currentTime);
    },
    getDayName(day) {
      const days = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      return days[day];
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';

.left-bottom,
.left-center,
.center-bottom,
.right-center,
.right-bottom {
  margin-top: 20px;
}

.center-header {
  width: 100%;
  font-size: 30px;
  height: 35px;
  font-family: cursive;
  text-align: center;
  padding-right: 110px;

  .center-header-size {
    padding: 0 10px;
    letter-spacing: 2px;
    font-weight: 700;
    background: linear-gradient(
            92deg,
            #0072ff 0%,
            #00eaff 48.8525390625%,
            #01aaff 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
